
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { PLAN_CREATOR_MODE, ROUTE_NAMES } from "@/defaults"
import createPlanName from "@/mixins/createPlanName"
import configuration from "@/configuration"
import { Component, Vue } from "vue-property-decorator"
import { PlanConfig, PlanResult, PlanValues } from "@/store/plan"
import {Destination, PlanCreatorMode, PlanType, PushPlanParams} from "@/types"
const { appUrl } = configuration
@Component({
  name: "DcsPlanFooter",
  computed: {
    ...mapState("plan", ["values", "config", "result"]),
    ...mapGetters(["isDcsOnboardingCompletedOnceAtLeast", "getFirstName"]),
    ...mapGetters("preferences", ["telegramChatId"]),
    ...mapGetters("plan", ["getBlockConfigValue", "getStrongestBlockPlanType"])
  },
  methods: {
    ...mapActions("plan", ["pushPlan"]),
    ...mapMutations(["setLoading"])
  }
})
export default class DcsPlanFooter extends Vue {
  private setLoading!: (isLoading: boolean) => void
  private config!: PlanConfig
  private result!: PlanResult
  private values!: PlanValues
  private pushPlan!: (params: PushPlanParams) => Promise<void>
  private isDcsOnboardingCompletedOnceAtLeast!: boolean
  private getFirstName!: string
  private telegramChatId!: number
  private getBlockConfigValue!: Function
  private getStrongestBlockPlanType!: PlanType | ""

  async pushPlanToTD(destination: Destination) {
    this.setLoading(true)

    const mode = this.$route.params.mode as PlanCreatorMode
    if (mode !== PLAN_CREATOR_MODE.CREATE && mode !== PLAN_CREATOR_MODE.EDIT) {
      throw new Error(`Invalid mode: ${mode}`)
    }

    const { historyId } = this.$route.query as { historyId?: string }
    const isDcs = this.config.isDcs ? this.config.isDcs : this.$route.name === ROUTE_NAMES.PLAN_DCS

    const dcsPlanName = createPlanName(
      this.values,
      this.getFirstName,
      this.getStrongestBlockPlanType,
      this.getBlockConfigValue("customPlan")
    )

    await this.pushPlan({
      name: dcsPlanName,
      historyId,
      mode,
      destination,
      isDcs
    })

    this.setLoading(false)
    await this.redirect()
  }
  async redirect() {
    const type = "open-calendar-dcs"
    await this.$router.push({ name: ROUTE_NAMES.DASHBOARD })
    if (this.isDcsOnboardingCompletedOnceAtLeast) return

    setTimeout(() => {
      window.parent.postMessage(
        {
          type
        },
        appUrl
      )
    }, 1000)
  }
}
