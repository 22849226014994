<template>
  <div class="cj-images">
    <div class="robert-black-round">
      <img src="@/assets/images/robert-full.png" alt="Coach Robert" />
    </div>
    <div class="cj-blue-round ml-8">
      <img src="@/assets/images/jack-full.svg" alt="Coach Jack" />
    </div>
    <div class="dcs-image">
      <img src="@/assets/images/dcs.svg" alt="DCS" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CoachImages"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.cj-images {
  display: flex;
  align-items: center;
  height: 150px;
}
.cj-blue-round {
  width: 130px;
  height: 130px;
  background: $black;
  margin-left: -20px;
  img {
    height: 180px;
    margin-top: 0;
  }
}
.dcs-image {
  width: 350px;
  height: 150px;
}
</style>
