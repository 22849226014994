<template>
  <BlueHeaderModal @close="closePushModal">
    <template v-slot:header>
      <label class="label">Coach Jack Notifications in Telegram.</label>
    </template>
    <template v-slot:body class="container">
      <Preferences @close="closePushModal" :fromDCS="true" />
    </template>
  </BlueHeaderModal>
</template>

<script>
import BlueHeaderModal from "@/components/BlueHeaderModal"
import "vue2-datepicker/index.css"
import Preferences from "@/views/preferences/Preferences.vue"

export default {
  name: "DcsPreferencesModal",
  components: {
    Preferences,
    BlueHeaderModal
  },
  computed: {},
  methods: {
    closePushModal() {
      this.$emit("close")
    }
  }
}
</script>
<style lang="scss"></style>
<style scoped lang="scss">
.container {
  display: flex;
}
::v-deep .blue-header-modal-container {
  max-width: 600px !important;
  min-width: 600px !important;
}
::v-deep .blue-header-modal-header {
  .field {
    width: 80% !important;
  }
}
</style>
